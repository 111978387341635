import React from 'react';
import { PageProps, Link } from 'gatsby';

import Container from '../components/layout/Container';
import Layout from '../components/layout/Layout';
import MetaData from '../components/seo/MetaData';
import Fruitbowl from "../components/images/icons/fruitbowl.png";
import CustomCard from '../components/cards/CustomCard';
import Lettuce from "../components/images/icons/lettuce.png";
import Eggplant from "../components/images/icons/eggplant.png";
import Grape from "../components/images/icons/grape.png";
import Cucumber from "../components/images/icons/cucumber.png";
import Cherry from "../components/images/icons/cherry.png";

import Apple from "../components/images/icons/apple.png";
import Corn from "../components/images/icons/corn.png";
import Beetroot from "../components/images/icons/beetroot.png";
import Carrot from "../components/images/icons/carrot.png";
import Lemon from "../components/images/icons/lemon.png";
import Tomato from "../components/images/icons/tomato.png";
import Potato from "../components/images/icons/potato.png";
import Avocado from "../components/images/icons/avocado.png";
import Eggs from "../components/images/icons/eggs.png";
import Mushrooms from "../components/images/icons/mushrooms.png";
import Nuts from "../components/images/icons/nuts.png";
import Cabbage from "../components/images/icons/cabbage.png";

import OrganizationStructuredData from '../components/seo/OrganizationStructuredData';
import Routes from '../constants/Routes';

const KategorierPage: React.FunctionComponent<PageProps> = () => {
  
  return (
    <Layout>
      <MetaData title="Kategorier" />
      <OrganizationStructuredData />
      <div className="bg-white">
        <Container className="pt-4 text-center">
          <h1>Kategorier</h1>
          <hr className="w-48 text-center mx-auto mb-2 border-2" />
        </Container>
        <Container>
        <div className="grid grid-cols-2 lg:grid-cols-3">
            <Link to={Routes.HOVEDRET}>
                <CustomCard className="text-center pr-1 pl-1 pt-0 mt-0 md:mr-6 mr-2 ml-0 md:ml-0">
                    <img 
                        src={Fruitbowl}
                        className="mt-0"
                        style={{ borderRadius: 10 }}
                        alt="Hovedret"
                    />
                    <h4 className="mt-0">
                        Hovedret
                    </h4>
                </CustomCard>
            </Link>
            <Link to={Routes.FORRET}>
                <CustomCard className="text-center pr-1 pl-1 pt-0 mt-0 md:mr-6 mr-2 ml-0 md:ml-0">
                    <img 
                        src={Eggplant}
                        className="mt-0"
                        style={{ borderRadius: 10 }}
                        alt="Forret"
                    />
                    <h4 className="mt-0">
                        Forret
                    </h4>
                </CustomCard>
            </Link>
            <Link to={Routes.SALAT}>
                <CustomCard className="text-center pr-1 pl-1 pt-0 mt-0 md:mr-6 mr-2 ml-0 md:ml-0">
                    <img 
                        src={Lettuce}
                        className="mt-0"
                        style={{ borderRadius: 10 }}
                        alt="Salat"
                    />
                    <h4 className="mt-0">
                        Salat
                    </h4>
                </CustomCard>
            </Link>
            <Link to={Routes.DESSERT}>
                <CustomCard className="text-center pr-1 pl-1 pt-0 mt-0 md:mr-6 mr-2 ml-0 md:ml-0">
                    <img 
                        src={Grape}
                        className="mt-0"
                        style={{ borderRadius: 10 }}
                        alt="Dessert"
                    />
                    <h4 className="mt-0">
                        Dessert
                    </h4>
                </CustomCard>
            </Link>
            <Link to={Routes.TILBEHØR}>
                <CustomCard className="text-center pr-1 pl-1 pt-0 mt-0 md:mr-6 mr-2 ml-0 md:ml-0">
                    <img 
                        src={Cucumber}
                        className="mt-0"
                        style={{ borderRadius: 10 }}
                        alt="Tilbehør"
                    />
                    <h4 className="mt-0">
                        Tilbehør
                    </h4>
                </CustomCard>
            </Link>
            <Link to={Routes.DRINKS}>
                <CustomCard className="text-center pr-1 pl-1 pt-0 mt-0 md:mr-6 mr-2 ml-0 md:ml-0">
                    <img 
                        src={Cherry}
                        className="mt-0"
                        style={{ borderRadius: 10 }}
                        alt="Drinks"
                    />
                    <h4 className="mt-0">
                        Drinks
                    </h4>
                </CustomCard>
            </Link>
        </div>
        </Container>

        <Container className="pt-8 text-center">
          <h2>Ingredienser</h2>
          <hr className="w-48 text-center mx-auto mb-2 border-2" />
        </Container>
        <Container>
        <div className="grid grid-cols-2 lg:grid-cols-3">
            <Link to={Routes.TOMAT}>
                <CustomCard className="text-center pr-1 pl-1 pt-0 mt-0 md:mr-6 mr-2 ml-0 md:ml-0">
                    <img 
                        src={Tomato}
                        className="mt-0"
                        style={{ borderRadius: 10 }}
                        alt="Tomat"
                    />
                    <h4 className="mt-0">
                        Tomat
                    </h4>
                </CustomCard>
            </Link>
            <Link to={Routes.ROEDBEDE}>
                <CustomCard className="text-center pr-1 pl-1 pt-0 mt-0 md:mr-6 mr-2 ml-0 md:ml-0">
                    <img 
                        src={Beetroot}
                        className="mt-0"
                        style={{ borderRadius: 10 }}
                        alt="Rødbede"
                    />
                    <h4 className="mt-0">
                        Rødbede
                    </h4>
                </CustomCard>
            </Link>
            <Link to={Routes.MAJS}>
                <CustomCard className="text-center pr-1 pl-1 pt-0 mt-0 md:mr-6 mr-2 ml-0 md:ml-0">
                    <img 
                        src={Corn}
                        className="mt-0"
                        style={{ borderRadius: 10 }}
                        alt="Majs"
                    />
                    <h4 className="mt-0">
                        Majs
                    </h4>
                </CustomCard>
            </Link>
            <Link to={Routes.GULEROD}>
                <CustomCard className="text-center pr-1 pl-1 pt-0 mt-0 md:mr-6 mr-2 ml-0 md:ml-0">
                    <img 
                        src={Carrot}
                        className="mt-0"
                        style={{ borderRadius: 10 }}
                        alt="Gulerod"
                    />
                    <h4 className="mt-0">
                        Gulerod
                    </h4>
                </CustomCard>
            </Link>
            <Link to={Routes.AEBLE}>
                <CustomCard className="text-center pr-1 pl-1 pt-0 mt-0 md:mr-6 mr-2 ml-0 md:ml-0">
                    <img 
                        src={Apple}
                        className="mt-0"
                        style={{ borderRadius: 10 }}
                        alt="Æble"
                    />
                    <h4 className="mt-0">
                        Æble
                    </h4>
                </CustomCard>
            </Link>
            <Link to={Routes.CITRON}>
                <CustomCard className="text-center pr-1 pl-1 pt-0 mt-0 md:mr-6 mr-2 ml-0 md:ml-0">
                    <img 
                        src={Lemon}
                        className="mt-0"
                        style={{ borderRadius: 10 }}
                        alt="Citron"
                    />
                    <h4 className="mt-0">
                        Citron
                    </h4>
                </CustomCard>
            </Link>
            <Link to={Routes.AEG}>
                <CustomCard className="text-center pr-1 pl-1 pt-0 mt-0 md:mr-6 mr-2 ml-0 md:ml-0">
                    <img 
                        src={Eggs}
                        className="mt-0"
                        style={{ borderRadius: 10 }}
                        alt="Æg"
                    />
                    <h4 className="mt-0">
                        Æg
                    </h4>
                </CustomCard>
            </Link>
            <Link to={Routes.AVOCADO}>
                <CustomCard className="text-center pr-1 pl-1 pt-0 mt-0 md:mr-6 mr-2 ml-0 md:ml-0">
                    <img 
                        src={Avocado}
                        className="mt-0"
                        style={{ borderRadius: 10 }}
                        alt="Avocado"
                    />
                    <h4 className="mt-0">
                        Avocado
                    </h4>
                </CustomCard>
            </Link>
            <Link to={Routes.NOEDDER}>
                <CustomCard className="text-center pr-1 pl-1 pt-0 mt-0 md:mr-6 mr-2 ml-0 md:ml-0">
                    <img 
                        src={Nuts}
                        className="mt-0"
                        style={{ borderRadius: 10 }}
                        alt="Nødder"
                    />
                    <h4 className="mt-0">
                        Nødder 
                    </h4>
                </CustomCard>
            </Link>
            <Link to={Routes.KAAL}>
                <CustomCard className="text-center pr-1 pl-1 pt-0 mt-0 md:mr-6 mr-2 ml-0 md:ml-0">
                    <img 
                        src={Cabbage}
                        className="mt-0"
                        style={{ borderRadius: 10 }}
                        alt="Kål"
                    />
                    <h4 className="mt-0">
                        Kål
                    </h4>
                </CustomCard>
            </Link>
            <Link to={Routes.SVAMPE}>
                <CustomCard className="text-center pr-1 pl-1 pt-0 mt-0 md:mr-6 mr-2 ml-0 md:ml-0">
                    <img 
                        src={Mushrooms}
                        className="mt-0"
                        style={{ borderRadius: 10 }}
                        alt="Svampe"
                    />
                    <h4 className="mt-0">
                        Svampe
                    </h4>
                </CustomCard>
            </Link>
            <Link to={Routes.KARTOFFEL}>
                <CustomCard className="text-center pr-1 pl-1 pt-0 mt-0 md:mr-6 mr-2 ml-0 md:ml-0">
                    <img 
                        src={Potato}
                        className="mt-0"
                        style={{ borderRadius: 10 }}
                        alt="Kartoffel"
                    />
                    <h4 className="mt-0">
                        Kartoffel
                    </h4>
                </CustomCard>
            </Link>
        </div>
        </Container>


      </div>
    </Layout>
  );
};

export default KategorierPage;